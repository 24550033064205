<template>
  <div v-click-outside="onClickOutside">
    <div class="vth-addr-label" v-if="label">{{ label }}</div>
    <div class="vth-addr-input-container">
      <v-text-field
        :type="type === 'zipcode' ? 'number' : 'text'"
        solo
        v-model="currentValue"
        :placeholder="placeholder"
        :disabled="disabled"
        :rules="rules"
        ref="input"
        @focus="hasFocus = true"
        @blur="hasFocus = false"
        @keydown.up="pressArrow('up')"
        @keydown.down="pressArrow('down')"
        @keydown.enter="pressEnter()"
      />
      <div
        v-if="resultsFromSearch.length && isOpenListContainer"
        ref="dropdown"
        class="vth-addr-list-container"
      >
        <div
          class="vth-addr-list"
          :class="{ 'vth-addr-list-on-focused': itemOnFocus === index }"
          :style="{
            'background-color': itemOnFocus === index ? currentColor : '#fff',
          }"
          v-for="(item, index) in resultsFromSearch"
          :key="index"
          @mouseover="itemOnFocus = index"
          @mouseout="itemOnFocus = -1"
          @click="clickSelectItem(item)"
        >
          <div class="vth-addr-box-item-top">
            <span
              class="item-first"
              :class="{
                'vth-addr-box-item-top-focused':
                  itemOnFocus === index && currentColor !== '#f5f5f5',
              }"
            >
              {{ itemFirst(item) }}
            </span>
            <div class="vth-addr-box-item-top">
              <span
                class="vth-addr-item-second"
                :class="{
                  'vth-addr-box-item-top-focused':
                    itemOnFocus === index && currentColor !== '#f5f5f5',
                }"
              >
                {{ itemSecond(item) }}
              </span>
            </div>
            <div class="vth-addr-box-item-top">
              <span
                class="vth-addr-item-third"
                :class="{
                  'vth-addr-box-item-top-focused':
                    itemOnFocus === index && currentColor !== '#f5f5f5',
                }"
              >
                {{ itemThird(item) }}
              </span>
            </div>
          </div>
          <div class="vth-addr-box-item-bottom">
            <span
              class="vth-addr-item-first vth-addr-font-weight-bold"
              :style="{
                color: itemOnFocus === index && currentColor !== '#f5f5f5' ? '#fff' : '#000',
              }"
            >
              {{ itemFourth(item) }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  searchAddressByDistrict,
  searchAddressByAmphoe,
  searchAddressByProvince,
  searchAddressByZipcode,
} from 'thai-address-database'
import vClickOutside from 'v-click-outside'
/*eslint-disable */

export default {
  name: 'VueThailandAddressAutocomplete',
  props: {
    value: {
      type: [String, Number]
    },
    type: {
      type: String
    },
    label: {
      type: String
    },
    placeholder: {
      type: String
    },
    color: {
      type: String
    },
    size: {
      type: String,
      default: 'default'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array
    }
  },
  data () {
    return {
      currentValue: this.value,
      currentColor: this.color || '#f5f5f5',
      itemOnFocus: 0,
      isOpenListContainer: false,
      hasFocus: false
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  computed: {
    resultsFromSearch () {
      if (this.type) {
        if (this.type === 'district') return this.resultsFromSearchByDistrict
        else if (this.type === 'amphoe') return this.resultsFromSearchByAmphoe
        else if (this.type === 'province') return this.resultsFromSearchByProvince
        else if (this.type === 'zipcode') return this.resultsFromSearchByZipcode
      } else {
        this._errorLog('type is undefined.')
        return []
      }
    },
    resultsFromSearchByDistrict () {
      return searchAddressByDistrict(this.currentValue)
    },
    resultsFromSearchByAmphoe () {
      return searchAddressByAmphoe(this.currentValue)
    },
    resultsFromSearchByProvince () {
      return searchAddressByProvince(this.currentValue)
    },
    resultsFromSearchByZipcode () {
      return searchAddressByZipcode(this.currentValue)
    }
  },
  watch: {
    currentValue (value) {
      this.$emit('input', this.currentValue)
      if (this.hasFocus) {
        this.isOpenListContainer = true
      }
      this.itemOnFocus = 0
    },
    value (value) {
      if (value !== this.currentValue) {
        this.currentValue = value
        this.$nextTick(() => this.isOpenListContainer = false)
      }
    },
    color (val) {
      this.currentColor = val
    }
  },
  methods: {
    onClickOutside (event) {
      this.isOpenListContainer = false
    },
    pressArrow (direction) {
      if (direction === 'up') {
        this.setInputCursorToLastChar()
        this.itemOnFocus = this.itemOnFocus > 0 ? this.itemOnFocus - 1 : 0
      } else {
        this.itemOnFocus = this.itemOnFocus < this.resultsFromSearch.length - 1 ? this.itemOnFocus + 1 : this.resultsFromSearch.length - 1
      }
      this.moveScrollOfListContainer()
    },
    pressEnter () {
      this.setSelectedValue(this.resultsFromSearch[this.itemOnFocus])
    },
    moveScrollOfListContainer () {
      const list = this.$refs.dropdown
      const element = list.querySelectorAll('.list')[this.itemOnFocus]
      if (!element) return
      const visMin = list.scrollTop
      const visMax = list.scrollTop + list.clientHeight - element.clientHeight
      if (element.offsetTop < visMin) {
        list.scrollTop = element.offsetTop
      } else if (element.offsetTop >= visMax) {
        list.scrollTop = (
          element.offsetTop -
          list.clientHeight +
          element.clientHeight
        )
      }
    },
    clickSelectItem (address) {
      this.setSelectedValue(address)
    },
    setSelectedValue (address) {
      this.$emit('select', address)
      this.type ? this.currentValue = address[this.type] : this._errorLog('type is undefined.')
      this.$nextTick(() => this.isOpenListContainer = false)
    },
    setInputCursorToLastChar () {
      const len = this.currentValue.length * 2
      setTimeout(() => { this.$refs.input.setSelectionRange(len, len) }, 1)
    },
    itemFirst (address) {
      return this.type === 'district' ? address['amphoe'] : address['district']
    },
    itemSecond (address) {
      return this.type === 'amphoe' || this.type === 'district' ? address['province'] : address['amphoe']
    },
    itemThird (address) {
      return this.type === 'province' || this.type === 'amphoe' || this.type === 'district' ? address['zipcode'] : address['province']
    },
    itemFourth (address) {
      return address[this.type]
    },
    _errorLog (text) {
      console.error(text)
    }
  }
}
</script>

<style scoped>
.vth-addr-input-container {
  float: left;
  width: 100%;
  position: relative;
}
.vth-addr-label {
  float: left;
  width: 100%;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #363636;
  margin-bottom: .75rem;
}
.vth-addr-input:focus {
  outline: none !important;
  border-radius: 3px;
}
.vth-addr-input-size-small {
  font-size: .75rem;
}
.vth-addr-input-size-default {
  font-size: 1rem;
}
.vth-addr-input-size-medium {
  font-size: 1.25rem;
}
.vth-addr-input-size-large {
  font-size: 1.5rem;
}
.vth-addr-list {
  float: left;
  width: 100%;
  padding: 12px;
}
.vth-addr-list-on-focused {
  cursor: pointer;
}
.vth-addr-box-item-top {
  color: rgba(0, 0, 0, 0.7);
  font-size: 16px;
  float: left;
  width: 100%;
  line-height: 14px;
}
.vth-addr-list-container {
  z-index: 999;
  width: 100%;
  position: absolute;
  left: 0;
  padding-top: 4px;
  max-height: 300px;
  overflow: auto;
  background-color: #ffffff;
  border-top: solid 1px #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 2px 3px hsla(0,0%,4%,.1), 0 0 0 1px hsla(0,0%,4%,.1);
}
.vth-addr-box-item-top-focused {
  color: rgba(255, 255, 255, 0.9);
}
.vth-addr-box-item-bottom {
  float: left;
  width: 100%;
  margin-top: 5px;
}
.vth-addr-item-first {
  float: left;
}
.vth-addr-item-second {
  float: left;
  margin-right: 15px;
}
.vth-addr-item-third {
  float: left;
}
.vth-addr-float-right {
  float: right;
}
.vth-addr-font-weight-bold {
  font-weight: bold;
}
</style>
